.loader{
  display:inline-block;
  position:absolute;
  right: 50%;
  margin-right: -75px;


  width: 16px;
  height: 16px;
  border: 1px solid rgb(56, 56, 56);
  border-top: 1px solid rgb(0, 174, 255);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin{
  0%    {transform: rotate(0deg);}
  100%  {transform: rotate(360deg);}

}