.profile-modal {
  background-color: rgba(65, 65, 65, 0.8);
  position:fixed;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
}