.App {
  text-align: center;
  display: flex ;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.particles{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  z-index:-1;
}